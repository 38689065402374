import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const WarrantiesPage = () => (
  <div>
      <SEO 
        title="Risk–Free Website Hosting" 
        description="With Sheephostingbay, you will enjoy an absolutely hassle–free cloud hosting service. 
        99.9% server uptime and 30–day money–back guarantees are included."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title">Risk–Free Website Hosting<span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">
                    With Sheephostingbay, you will enjoy an absolutely hassle–free cloud hosting service. 
                    99.9% server uptime and 30–day money–back guarantees are included.</p>
            </div>
        </div>
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/99-service-uptime-02.webp" className="w-60" alt="Web Hosting Control Panel" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A 99.9% Server Uptime</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    The chief benefit of building your very own website hosting platform is that you don’t need to be worried about 
                                    compatibility issues – you design a particular set of software apps in such a way as to work with a specific 
                                    set of hardware parts. The final outcome is something considerably more solid and reliable, since there are 
                                    fewer potential risks of failure. We have set up our cloud hosting system atop proven hardware elements in 
                                    addition to a significantly modified version of SELinux in order to create a very secure and safe 
                                    environment for your personal web sites.
                                    <br/><br/>
                                    This is why, with each of our website hosting packages we give you a 99.9% server uptime guarantee. This 
                                    uptime guarantee is valid for all our datacenters.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/99-network-uptime-02.webp" className="w-60" alt="A 99.9% Network Uptime" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A 99.9% Network Uptime</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    When you develop your very own cloud hosting system, it’s essential that you also set up your own network 
                                    architecture. That is the reason why we set up a custom internal network in each one of our data centers. 
                                    Relying on topflight Juniper routers and switches, our internal network allows for extremely fast data 
                                    transfer speeds and is also fully redundant.
                                    <br/><br/>
                                    Because of this, you can enjoy a 99.9% network uptime in our USA datacenter, in our United Kingdom 
                                    datacenter, in our datacenter Finland, in our Eastern Europe datacenter as well as in our 
                                    Australian datacenter, irrespective of which hosting package you have selected.
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/30-days-money-back-02.webp" className="w-60" alt="A 30–Day Money–Back Guarantee" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A 30–Day Money–Back Guarantee</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Our company offers a 30–day money–back guarantee with all our cloud hosting packages. If you are unhappy 
                                    with our hosting services, or if you think that they are inadequate, or if you decide that you require 
                                    a VPS packages rather than a cloud hosting account – you will get your money back, no questions asked.
                                    <br/><br/>
                                    All domain name registrations are nonrefundable, but you will retain total control over your domain names 
                                    even if you delete your web hosting account with us.
                                    </div>
                                </div>
                            </div>
                        </div>       

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/24-support-02.webp" className="w-60" alt="24/7 Technical Support" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">24/7 Technical Support</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    We have cautiously handpicked each member of our 24x7 support department to make sure that we can provide the 
                                    very best tech support service that you can possibly get. Each one of our technicians has several years of 
                                    experience dealing with hosting accounts behind his back, so they know very well what they are doing. 
                                    Besides, they’re at your disposal 24–7–365 – you’ll be able to touch base with them at any time and 
                                    they’ll respond to you as fast as possible.
                                    <br/><br/>
                                    Their average response time is below 20 minutes, whereas with other hosting providers you need to wait 
                                    around for days. Moreover, we offer a 1–hour response time guarantee. Regardless of what happens, 
                                    your inquiries will be answered in less than 1 hour.
                                    </div>
                                </div>
                            </div>
                        </div>      
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default WarrantiesPage
